<template>
  <div class="innerContent">
    <!-- <v-container class="my-0 py-0"> -->
    <v-row no-gutters class="pa-2">
      <v-col cols="12" sm="3">
        <v-card outlined flat style="border-radius: 20px">
          <v-card-text>
            <div class="d-flex align-center" style="flex-direction: column">
              <!-- <span style="opacity: 0.8" class="mb-1">File Progress</span> -->
              <v-container>
                <v-row justify="center" align="center">
                  <v-col cols="12" sm="4" class="text-center my-0 py-0">
                    <v-progress-circular
                      rotate="270"
                      :size="100"
                      :width="12"
                      :value="progress"
                      :color="
                        progress == 0
                          ? 'grey'
                          : progress < 20
                          ? 'red'
                          : progress < 50
                          ? 'orange'
                          : progress < 100
                          ? 'blue'
                          : 'success'
                      "
                    >
                      {{ progress + "%" }}
                    </v-progress-circular>
                  </v-col>
                  <v-col cols="12" sm="8" class="mx-0 px-0 my-0 py-0">
                    <v-list dense class="mx-0 px-0">
                      <v-list-item
                        v-for="(metric, index) in metrics"
                        :key="index"
                        style="height: 30px"
                      >
                        <v-list-item-action class="mx-0 px-0 mr-1">
                          <v-icon
                            :color="
                              metric.percentage == 0
                                ? 'grey'
                                : metric.percentage < 20
                                ? 'red'
                                : metric.percentage < 50
                                ? 'orange'
                                : metric.percentage < 100
                                ? 'blue'
                                : 'success'
                            "
                            >fiber_manual_record</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content class="ml-0 pl-0">
                          <v-list-item-title style="font-size: 12px">
                            {{ metric.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 11px">
                            {{ metric.value }}/{{ metric.total }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-container>
            </div>
            <v-list dense>
              <v-list-item
                @click="
                  $store.state.currentOrg.id == shipment.forwarderId
                    ? (statusConfirmationDialog = true)
                    : null
                "
                style="height: 30px"
              >
                <v-list-item-action>
                  <v-avatar size="32">
                    <v-icon
                      :color="getMainStatusColor(shipment.documentationStatus)"
                      >fiber_manual_record</v-icon
                    >
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 12px">
                    {{ shipment.documentationStatus }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 11px">
                    File Status
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="documentUserAssign = true"
                :key="documentUserKey"
                style="height: 30px"
              >
                <v-list-item-action>
                  <v-avatar size="32" v-if="!shipment.assignedDocumentUser">
                    <v-icon color="grey"> person </v-icon>
                  </v-avatar>
                  <v-avatar
                    v-else
                    :color="
                      shipment.assignedDocumentUser.avatar
                        ? 'white'
                        : 'secondary'
                    "
                    size="32"
                  >
                    <v-img
                      v-if="shipment.assignedDocumentUser.avatar"
                      :src="shipment.assignedDocumentUser.avatar"
                      contain
                    ></v-img>
                    <h3 v-else>
                      {{ shipment.assignedDocumentUser.firstname.charAt(0) }}
                    </h3>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content class="text-left">
                  <v-list-item-title style="font-size: 12px">
                    {{
                      getDocumentUserDescription(shipment.assignedDocumentUser)
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    style="font-size: 11px"
                    v-if="shipment.assignedDocumentUserId"
                  >
                    Assigned Documentation User
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="documentProcessingOfficeModal = true"
                style="height: 30px"
              >
                <v-list-item-action>
                  <v-avatar size="32">
                    <v-icon color="grey"> location_on </v-icon>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content class="text-left">
                  <v-list-item-title style="font-size: 12px">
                    {{ getAddressAlias(shipment.documentProcessingAddressId) }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    style="font-size: 11px"
                    v-if="shipment.documentProcessingAddressId"
                  >
                    Document Processing Office
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list dense class="my-0 py-0">
              <v-divider></v-divider>
              <v-subheader style="font-size: 14px"
                >Events <v-spacer></v-spacer>
                <v-tooltip top v-if="shipment.documentationStatus == 'OPEN'">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      color="primary"
                      @click="addCustomEvent"
                    >
                      <v-icon>add_circle_outline</v-icon>
                    </v-btn>
                  </template>
                  Add Custom Event
                </v-tooltip></v-subheader
              >
              <v-divider></v-divider>
              <v-list-item
                v-if="events.length == 0 && customEvents.length == 0"
              >
                <v-list-item-content class="text-center">
                  <span style="color: grey; font-size: 11px"
                    >No available events</span
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="event in events"
                :key="event.id"
                @click="viewEvent(event)"
                style="height: 30px"
              >
                <v-list-item-action>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        small
                        v-on="on"
                        :color="getStatusColor(event.status)"
                        >{{ getStatusIcon(event.status) }}</v-icon
                      >
                    </template>
                    <span>{{ event.status }}</span>
                  </v-tooltip>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 12px">
                    {{ event.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    v-if="event.reference"
                    style="font-size: 11px"
                  >
                    {{ event.reference }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-for="event in customEvents"
                :key="event.id"
                @click="editCustomEvent(event)"
                style="height: 30px"
              >
                <v-list-item-action>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        small
                        v-on="on"
                        :color="getStatusColor(event.status)"
                        >{{ getStatusIcon(event.status) }}</v-icon
                      >
                    </template>
                    <span>{{ event.status }}</span>
                  </v-tooltip>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 12px">
                    {{ event.shipmentCustomEventType.description }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    v-if="event.reference"
                    style="font-size: 11px"
                  >
                    {{ event.reference }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-subheader style="font-size: 14px">Mass Actions</v-subheader>
              <v-divider></v-divider>
              <v-list-item
                v-for="(action, index) in availableMassActions"
                :key="index"
                @click="startMassAction(action)"
                style="height: 30px"
              >
                <v-list-item-action>
                  <v-icon color="grey" small>fiber_manual_record</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 12px" class="text-wrap">
                    {{ action.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 11px" class="text-wrap" v-if="action.document">
                    {{ action.document }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-progress-circular
                    v-if="startingAction == action.code"
                    color="primary"
                    size="20"
                    indeterminate
                  ></v-progress-circular>
                </v-list-item-action>
              </v-list-item>
              <v-list-item v-if="availableMassActions.length == 0">
                <v-list-item-content class="text-center">
                  <span style="color: grey; font-size: 11px"
                    >No available mass actions</span
                  >
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-subheader style="font-size: 14px"
                >Special Instructions</v-subheader
              >
              <v-divider></v-divider>
              <v-list-item
                v-for="special in documentSpecialComments"
                :key="special.id"
              >
                <v-list-item-content>
                  <v-list-item-title style="font-size: 12px">
                    {{ special.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    v-if="special.comment"
                    class="text-wrap"
                    style="font-size: 11px"
                  >
                    <b> Comment: </b> {{ special.comment }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    class="text-wrap"
                    style="font-size: 11px"
                    v-if="special.specialInstruction"
                  >
                    <b>Special Note: </b> {{ special.specialInstruction }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="documentSpecialComments.length == 0">
                <v-list-item-content
                  class="text-center"
                  style="font-size: 11px"
                >
                  <span style="color: grey"
                    >No Special Instructions or comments</span
                  >
                </v-list-item-content></v-list-item
              >
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="9"
        class="mx-0 px-0 text-center"
        style="max-width: 100%; overflow-x: auto"
      >
        <v-toolbar flat dense>
          <v-spacer></v-spacer>
          <v-chip>
            <v-tooltip
              top
              v-if="shipment.shipmentFile && shipment.shipmentFile.fileCover"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  icon
                  v-on="on"
                  @click="previewFileCoverModal = true"
                >
                  <v-icon small>article</v-icon>
                </v-btn>
              </template>
              View File Cover
            </v-tooltip>

            <v-tooltip top v-else>
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  icon
                  v-on="on"
                  id="generate-file-cover-document-button"
                  :loading="generatingFileCover"
                  @click="generateCover()"
                >
                  <v-icon small>post_add</v-icon>
                </v-btn>
              </template>
              Generate File Cover
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  icon
                  v-on="on"
                  id="add-document-button"
                  @click="addManualDocument()"
                >
                  <v-icon small>add_circle_outline</v-icon>
                </v-btn>
              </template>
              Add document manually
            </v-tooltip>
            <v-text-field
              placeholder="Search"
              class="mb-1"
              hide-details
              rounded
              clearable
              dense
              style="width: 300px"
              v-model="search"
            ></v-text-field>
          </v-chip>
        </v-toolbar>
        <div
          :style="{
            width: tableWidth,
          }"
        >
          <table :width="'100%'">
            <tr>
              <td
                v-for="(header, index) in headers"
                :key="index"
                :style="{
                  width: header.width ?? '150px',
                  'border-right': '1px solid grey',
                }"
                class="text-center mt-0 mb-0 pb-0"
              >
                <h5>
                  {{ header.text }}
                </h5>
              </td>
            </tr>
          </table>
     
          <v-virtual-scroll
            :key="refreshKey"
            class="my-0 py-0 px-1"
            :bench="0"
            :width="'100%'"
            :items="filteredDocuments"
            height="1000"
            item-height="30"
            style="font-size: 11px"
          >
            <template v-slot:default="{ item }">
              <div
                class="text-center my-0 py-0"
                :style="{
                  alignItems: 'center',
                  display: 'flex',
                  'flex-direction': 'row',
                  width: '100%',
                }"
              >
                <div
                  v-if="item.type == 'subheader'"
                  :style="{ width: tableWidth }"
                >
                  <v-toolbar
                    flat
                    dense
                    style="
                      background-color: var(--v-toolbar-lighten1) !important;
                    "
                    height="28"
                  >
                    <v-toolbar-title>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <span v-on="on" style="font-size: 13px">
                            {{ item.value.name }}
                          </span>
                        </template>
                        <span style="font-size: 12px"
                          >Rank: {{ item.value.rank }}</span
                        >
                      </v-tooltip>
                    </v-toolbar-title>
                    <!-- <v-tooltip
                        top
                        v-for="(action, index) in item.massActions"
                        :key="index"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            v-on="on"
                            :loading="startingAction"
                            color="primary"
                            class="mx-1"
                            @click="startMassAction(action)"
                            ><v-icon>category</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px"
                          >Mass Action: {{ action.name }}</span
                        >
                      </v-tooltip> -->
                  </v-toolbar>
                </div>
                <div v-else :style="{ width: tableWidth }">
                  <v-container :style="{ width: tableWidth }" fluid>
                    <v-row align="center" justify="center">
                      <td
                        v-for="(header, index) in headers"
                        :key="index"
                        :style="{ width: header.width ?? '150px' }"
                        class="text-center"
                      >
                        <div v-if="header.value == 'actions'">
                          <v-btn-toggle
                            borderless
                            style="background-color: transparent"
                            :max="0"
                            multiple
                            :value="[]"
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  small
                                  @click="viewDocument(item)"
                                >
                                  <v-icon small>open_in_new</v-icon>
                                </v-btn>
                              </template>
                              <span style="font-size: 12px"
                                >View Document Details</span
                              >
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  :loading="tableBillableStates[item.id]"
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  small
                                  @click="updateDocumentBillable(item)"
                                >
                                  <v-icon
                                    small
                                    :color="item.isBillable ? 'green' : 'grey'"
                                    >{{
                                      item.isBillable ? "paid" : "attach_money"
                                    }}</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span style="font-size: 12px"
                                >Change Billable Status</span
                              >
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  :loading="tableDeleteStates[item.id]"
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  small
                                  color="red"
                                  @click="deleteShipmentDocumentType(item)"
                                >
                                  <v-icon small color="red">delete</v-icon>
                                </v-btn>
                              </template>
                              <span style="font-size: 12px"
                                >Delete Document</span
                              >
                            </v-tooltip>
                          </v-btn-toggle>
                        </div>
                        <div v-else-if="header.value == 'status'">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on" small>
                                <v-icon :color="getStatusColor(item.status)">{{
                                  getStatusIcon(item.status)
                                }}</v-icon>
                              </v-btn>
                            </template>
                            <span style="font-size: 12px">{{
                              item.status
                            }}</span>
                          </v-tooltip>
                        </div>
                        <div v-else-if="header.value == 'integrationStatus'">
                          <v-tooltip top v-if="item.integrationStatus">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on" small>
                                <v-icon
                                  :color="
                                    getStatusColor(item.integrationStatus)
                                  "
                                  >{{
                                    getStatusIcon(item.integrationStatus)
                                  }}</v-icon
                                >
                              </v-btn>
                            </template>
                            <span style="font-size: 12px">{{
                              item.integrationStatus
                            }}</span>
                          </v-tooltip>
                        </div>
                        <div v-else-if="header.value == 'reference'">
                          <span v-if="item.reference">
                            {{ item.reference }}
                          </span>
                          <span v-else>-</span>
                        </div>
                        <div v-else-if="header.value == 'group'">
                          <span v-if="item.bookingContainer">{{
                            item.bookingContainer.containerNo
                          }}</span>
                          <v-chip
                            small
                            v-else-if="item.quantityType != 'Shipment'"
                            >{{
                              item.shipmentDocumentTypeContainers.length
                            }}</v-chip
                          >
                          <v-chip small outlined v-else>All</v-chip>
                        </div>
                        <div v-else-if="header.value == 'responsiblePartyType'">
                          <v-menu
                            transition="scale-transition"
                            v-if="item.responsiblePartyType"
                          >
                            <template
                              v-slot:activator="{ on: menu, attrs: menuAttrs }"
                            >
                              <v-tooltip top>
                                <template
                                  v-slot:activator="{
                                    on: tooltip,
                                    attrs: tooltipAttrs,
                                  }"
                                >
                                  <v-icon
                                    small
                                    v-bind="{ ...menuAttrs, ...tooltipAttrs }"
                                    v-on="{ ...menu, ...tooltip }"
                                    >{{
                                      getIcon(item.responsiblePartyType)
                                    }}</v-icon
                                  >
                                </template>
                                <span
                                  style="
                                    font-size: 12px;
                                    text-transform: capitalize;
                                  "
                                  >{{ item.responsiblePartyType }}</span
                                >
                              </v-tooltip>
                            </template>
                            <v-card>
                              <v-list>
                                <v-list-item
                                  v-for="party in documentResponsibleParties"
                                  :key="party.id"
                                  @click="
                                    setDocumentResponsibleParty(
                                      party.value,
                                      item
                                    )
                                  "
                                >
                                  <v-list-item-content>
                                    <v-list-item-title>{{
                                      party.name
                                    }}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-card>
                          </v-menu>
                        </div>
                        <div v-else-if="header.value == 'kpiCategory'">
                          <v-tooltip top v-if="item.kpiCategory">
                            <template v-slot:activator="{ on }">
                              <v-icon
                                v-on="on"
                                v-if="item.kpiCategory == 'GOOD'"
                                color="success"
                              >
                                check_circle_outline
                              </v-icon>
                              <v-icon
                                v-on="on"
                                v-else-if="item.kpiCategory == 'DANGER'"
                                color="orange"
                              >
                                warning
                              </v-icon>
                              <v-icon
                                v-on="on"
                                v-else-if="item.kpiCategory == 'OVERDUE'"
                                color="red"
                              >
                                error
                              </v-icon>
                            </template>
                            <span style="font-size: 12px">{{
                              item.kpiCategory
                            }}</span>
                          </v-tooltip>
                        </div>
                        <div v-else-if="header.value == 'courier'">
                          <v-icon v-if="item.courier" color="success"
                            >check</v-icon
                          >
                        </div>
                        <div v-else>
                          <span v-if="item[header.value]">
                            {{ item[header.value] }}
                          </span>
                        </div>
                      </td>
                    </v-row>
                  </v-container>
                </div>
              </div>
            </template>
          </v-virtual-scroll>
        </div>
      </v-col>
    </v-row>
    <!-- </v-container> -->

    <v-dialog
      v-model="documentProcessingOfficeModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Select an Address</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="documentProcessingOfficeModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list dense>
            <v-list-item v-if="addresses.length == 0">
              <v-list-item-content class="text-center">
                <span style="font-size: 12px; color: grey"
                  >No addresses found</span
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="address in addresses"
              :key="address.id"
              @click="setDocumentAddess(address)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ address.alias }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="documentUserAssign"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="savingDocumentationUser">
        <v-toolbar flat>
          <v-toolbar-title>Assign Documentation User</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="documentUserAssign = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            dense
            prepend-inner-icon="search"
            v-model="searchUser"
            clearable
            placeholder="Search"
          ></v-text-field>
          <v-list dense style="max-height: 60vh; overflow-y: auto">
            <v-list-item v-if="filterUsers.length == 0">
              <v-list-item-content class="text-center">
                <span style="font-size: 12px; color: grey">No users found</span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="user in filterUsers"
              :key="user.id"
              @click="assignDocumentUser(user)"
            >
              <v-list-item-action>
                <v-avatar :color="user.user.avatar ? 'white' : 'secondary'">
                  <v-img
                    v-if="user.user.avatar"
                    :src="user.user.avatar"
                    contain
                  ></v-img>
                  <h3 v-else>{{ user.user.firstname.charAt(0) }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  >{{ user.user.firstname }}
                  {{ user.user.surname }}</v-list-item-title
                >
                <v-list-item-subtitle v-if="user.position">
                  <v-icon small color="grey" class="mr-2">work</v-icon>
                  {{ user.position }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="previewFileCoverModal"
      width="1200px"
      :fullscreen="fullscreen || $vuetify.breakpoint.mobile"
    >
      <v-card
        v-if="
          shipment.shipmentFile &&
          shipment.shipmentFile.fileCover &&
          shipment.shipmentFile.fileCover.name
        "
      >
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            {{ shipment.shipmentFile.fileCover.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn @click="generateCover()" :loading="generatingFileCover" icon
            ><v-icon>refresh</v-icon></v-btn
          >
          <v-btn
            @click="downloadUrlDocument(shipment.shipmentFile.fileCover)"
            icon
            ><v-icon>download</v-icon></v-btn
          >
          <v-btn @click="fullscreen = !fullscreen" icon
            ><v-icon v-if="!fullscreen">fullscreen</v-icon
            ><v-icon v-else>fullscreen_exit</v-icon></v-btn
          >
          <v-btn icon text @click="previewFileCoverModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text
          :style="{
            'max-height': fullscreen ? '90vh' : '75vh',
            'overflow-y': 'auto',
          }"
        >
          <v-row>
            <v-col cols="12" :loading="imageLoading">
              <div
                v-if="
                  shipment.shipmentFile.fileCover.type &&
                  shipment.shipmentFile.fileCover.type.includes('image/')
                "
              >
                <v-img
                  :src="shipment.shipmentFile.fileCover.url"
                  contain
                  style="max-height: 70vh"
                  @load="imageLoading = false"
                >
                </v-img>
              </div>
              <div
                v-else-if="
                  shipment.shipmentFile.fileCover.type == 'application/pdf'
                "
              >
                <v-row justify="center">
                  <v-col
                    cols="12"
                    sm="9"
                    class="text-center"
                    v-if="shipment.shipmentFile.fileCover.url"
                  >
                    <div ref="pdfBox">
                      <VuePdfEmbed
                        :width="fullscreen ? 1200 : 900"
                        :source="shipment.shipmentFile.fileCover.url"
                        @loaded="imageLoading = false"
                      />
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-container v-else fill-height>
                <v-row justify="center" height="100%">
                  <div class="text-center">
                    <span
                      >This file is in a format that cannot be previewed.</span
                    >
                    <br /><br />
                    <v-btn
                      color="primary"
                      style="text-transform: none"
                      @click="
                        downloadUrlDocument(shipment.shipmentFile.fileCover)
                      "
                    >
                      <v-icon class="mr-1">download</v-icon> Download</v-btn
                    >
                  </div>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="documentModal" fullscreen>
      <div v-if="documentItem && documentItem.id">
        <DocumentModal
          :documentUpdateKey="documentUpdateKey"
          :openKey="openKey"
          @close="documentModal = false"
          :item="shipment"
          :actions="actions"
          :bookings="containers"
          :documentItem="documentItem"
          :userScope="userScope"
          :tasks="documentTasks(documentItem)"
          :documents="documents"
          :documentTypes="documents"
          :relationships="relationships"
          :uploads="uploads"
          :shipment="shipment"
          @updateDocument="updateDocument"
          :fileNumber="shipment.shipmentFile ? shipment.shipmentFile.fileNumber:''"
          :taskKey="taskKey"
          @getShipmentDocuments="getUploads"
          @refreshDocument="refreshDocument"
        />
      </div>
    </v-dialog>

    <v-dialog
      v-model="addDocumentModal"
      width="1400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title v-if="!manualDocument || !manualDocument.id"
            >Select a Document</v-toolbar-title
          >
          <v-toolbar-title v-else>{{ manualDocument.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(addDocumentModal = false), (manualDocument = {})"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text v-if="!manualDocument || !manualDocument.id">
          <v-row justify="end">
            <v-col cols="12" md="6">
              <v-text-field
                label="Search"
                prepend-inner-icon="search"
                v-model="searchAdditionalDocuments"
                outlined
                dense
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            style="cursor: pointer"
            :loading="loadingAdditionalDocuments"
            :items="countryDocuments"
            :headers="additionalDocumentHeaders"
            :search="searchAdditionalDocuments"
            @click:row="selectManualDocument"
          >
            <template v-slot:[`item.iso2`]="{ item }">
              <v-avatar size="32">
                <v-img
                  contain
                  v-if="item.iso2"
                  :src="`https://cdn.loglive.io/flags/4x3/${item.iso2
                    .substring(0, 2)
                    .toLowerCase()}.svg`"
                ></v-img>
              </v-avatar>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="selectVariationModal"
      width="600px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card v-if="manualDocument && manualDocument.subtypes">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Select Variation</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="selectVariationModal = false" text>X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-item
              @click="setSubtype(subtype)"
              v-for="subtype in manualDocument.subtypes"
              :key="subtype.id"
            >
              <v-list-item-content>
                <v-list-item-title>{{ subtype.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="setQuantityModal"
      width="600px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card v-if="manualDocument && manualDocument.locationDocument">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Set Quantity</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="setQuantityModal = false" text>X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-item
              v-if="manualDocument.parentShipmentDocument"
              @click="selectVariationModal = true"
            >
              <v-list-item-action>
                <v-icon>description</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  manualDocument.parentShipmentDocument.name
                }}</v-list-item-title>
                <v-list-item-subtitle
                  >Variation:
                  {{
                    manualDocument.locationDocument.name
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else>
              <v-list-item-action>
                <v-icon>description</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  manualDocument.locationDocument.name
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-card
              outlined
              @click="(manualDocument.quantityType = 'Shipment'), typeKey++"
              class="text-center mt-2"
              style="border: 1px solid orange; border-radius: 20px"
            >
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="orange">directions_boat</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">
                    Per Shipment
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action :key="typeKey">
                  <v-icon
                    color="success"
                    v-if="manualDocument.quantityType == 'Shipment'"
                    >check_circle_outline</v-icon
                  >
                </v-list-item-action>
              </v-list-item>
            </v-card>

            <v-card
              outlined
              @click="(manualDocument.quantityType = 'Container'), typeKey++"
              class="text-center mt-2"
              style="border: 1px solid teal; border-radius: 20px"
            >
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="teal">widgets</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">
                    {{ shipment.breakBulkShipment ? "Per CTO" : "Per Container" }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action :key="typeKey">
                  <v-icon
                    color="success"
                    v-if="manualDocument.quantityType == 'Container'"
                    >check_circle_outline</v-icon
                  >
                </v-list-item-action>
              </v-list-item>
            </v-card>

            <v-card
              outlined
              @click="
                (manualDocument.quantityType = 'CountContainer'), typeKey++
              "
              class="text-center mt-2"
              style="border: 1px solid grey; border-radius: 20px"
            >
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="blue-grey">widgets</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">
                    Per x
                    {{ shipment.breakBulkShipment ? "CTO(s)" : "Container(s)" }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <el-input-number
                      :min="1"
                      size="mini"
                      v-model="manualDocument.count"
                    ></el-input-number>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action :key="typeKey">
                  <v-icon
                    color="success"
                    v-if="manualDocument.quantityType == 'CountContainer'"
                    >check_circle_outline</v-icon
                  >
                </v-list-item-action>
              </v-list-item>
            </v-card>

            <v-card
              v-if="!shipment.breakBulkShipment"
              outlined
              @click="
                (manualDocument.quantityType = 'SpecifyContainer'), typeKey++
              "
              class="text-center mt-2"
              style="border: 1px solid grey; border-radius: 20px"
            >
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="red">widgets</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">
                    Specify Containers
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action :key="typeKey">
                  <v-icon
                    color="success"
                    v-if="manualDocument.quantityType == 'SpecifyContainer'"
                    >check_circle_outline</v-icon
                  >
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </v-list>
          <v-row justify="center" class="mt-2">
            <v-btn
              v-if="manualDocument.quantityType != 'SpecifyContainer'"
              color="primary"
              text style="text-transform: none"
              @click="createManualDocuments()"
              :loading="creatingManualDocuments"
              >Create Document(s)</v-btn
            >
            <v-btn
              v-else
              color="primary"
              text style="text-transform: none"
              @click="selectContainersModal = true"
              :loading="creatingManualDocuments"
              >Select Container(s)</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="selectContainersModal"
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Select Containers </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="selectContainersModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-switch
            v-model="manualDocument.individualDocuments"
            color="success"
            label="Create document per container"
          ></v-switch>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="container in containers"
              :key="container.id"
              @click="addContainer(container.id)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ container.containerNo }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ container.ctoNo }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action :key="manualDocumentContainerKey">
                <v-icon
                  color="green"
                  v-if="
                    manualDocument &&
                    manualDocument.containers &&
                    manualDocument.containers.includes(container.id)
                  "
                  >check_circle_outline</v-icon
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-row justify="center" class="mt-2">
            <v-btn
              :disabled="
                !manualDocument.containers ||
                manualDocument.containers.length == 0
              "
              color="primary"
              text
              @click="createManualDocuments()"
              :loading="creatingManualDocuments"
              >Create Document(s)</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="eventModal"
      width="1000px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <EventModal
        :event="event"
        :documents="uploads"
        :shipment="shipment"
        @close="eventModal = false"
        @update="updateEvent"
        @refreshEvents="getEvents"
        :allowDelete="true"
      />
    </v-dialog>

    <v-dialog
      v-model="customEventModal"
      persistent
      width="800px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent"
          ><v-toolbar-title>Create Custom Event</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn id="info-guide" @click="csStartGuide" fab height="4vh" width="4vh">
            <v-icon>help</v-icon>
          </v-btn> -->
          <v-btn
            id="event-save"
            color="primary"
            icon
            :disabled="validateCustomEvent"
            :loading="savingCustomEvent"
            @click="saveCustomEvent"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn
            text
            @click="(customEventModal = false), (customEventItem = {})"
            >X</v-btn
          ></v-toolbar
        >
        <v-card-text>
          <v-row>
            <v-col>
              <v-autocomplete
                id="event-type"
                label="Event Type*"
                :items="customEventTypes"
                v-model="customEventItem.shipmentCustomEventTypeId"
                outlined
                dense
                clearable
                item-text="description"
                item-value="id"
                prepend-icon="format_list_bulleted"
              ></v-autocomplete>
              <v-autocomplete
                id="event-status"
                :items="availableParties"
                v-model="customEventItem.courier"
                label="Courier To"
                outlined
                dense
                v-if="customEventItem.shipmentCustomEventTypeId == 7"
                clearable
                item-text="name"
                item-value="value"
                prepend-icon="local_shipping"
              ></v-autocomplete>
              <v-autocomplete
                id="event-adress"
                :items="orgAdresses"
                v-model="customEventItem.destinationAddressId"
                label="Courier Address"
                outlined
                dense
                v-if="customEventItem.courier && orgAdresses.length >= 1"
                clearable
                item-text="fullAdress"
                item-value="id"
                prepend-icon="location_on"
              ></v-autocomplete>
              <v-autocomplete
                id="event-status"
                v-if="
                  customEventItem.shipmentCustomEventTypeId &&
                  customEventItem.shipmentCustomEventTypeId != 7
                "
                :items="customEventStatuses"
                v-model="customEventItem.status"
                label="Event Status"
                outlined
                dense
                clearable
                item-text="name"
                item-value="value"
                prepend-icon="star_outline"
              ></v-autocomplete>

              <v-text-field
                id="event-description"
                v-if="
                  customEventItem.shipmentCustomEventTypeId &&
                  customEventItem.shipmentCustomEventTypeId != 7
                "
                outlined
                dense
                v-model="customEventItem.reference"
                placeholder="Reference"
                prepend-icon="description"
              ></v-text-field>
              <v-textarea
                id="event-comment"
                v-if="
                  customEventItem.shipmentCustomEventTypeId &&
                  !customEventItem.shipmentCustomEventType.eventCode
                "
                outlined
                rows="4"
                v-model="customEventItem.comment"
                placeholder="Comment"
                prepend-icon="comment"
                dense
              ></v-textarea>
              <div
                v-if="
                  customEventItem.status == 'Rejected' ||
                  customEventItem.status == 'REJECTED'
                "
              >
                <v-text-field
                  id="event-rejected-reason"
                  v-if="
                    customEventItem.shipmentCustomEventTypeId &&
                    !customEventItem.shipmentCustomEventType.eventCode
                  "
                  outlined
                  dense
                  v-model="customEventItem.rejectReason"
                  placeholder="Reason Rejected"
                  prepend-icon="comments_disabled"
                ></v-text-field>
                <v-text-field
                  id="event-fault-party"
                  v-if="
                    customEventItem.shipmentCustomEventTypeId &&
                    !customEventItem.shipmentCustomEventType.eventCode
                  "
                  outlined
                  dense
                  v-model="customEventItem.faultParty"
                  placeholder="Fault Party"
                  prepend-icon="dangerous"
                ></v-text-field>
              </div>
            </v-col>

            <v-col
              v-if="
                customEventItem.shipmentCustomEventTypeId &&
                !customEventItem.shipmentCustomEventType.eventCode
              "
            >
              <div v-if="customEventItem.shipmentCustomEventTypeId === 1">
                <v-autocomplete
                  id="event-pickup"
                  label="Pickup Location*"
                  :items="organisationAddresses"
                  v-model="customEventItem.pickupAddressId"
                  outlined
                  dense
                  clearable
                  item-text="alias"
                  item-value="id"
                  prepend-icon="home_filled"
                ></v-autocomplete>
                <v-autocomplete
                  id="event-delivery"
                  label="Delivery Location*"
                  :items="organisationAddresses"
                  v-model="customEventItem.deliveryAddressId"
                  outlined
                  dense
                  clearable
                  item-text="alias"
                  item-value="id"
                  prepend-icon="home"
                ></v-autocomplete>
              </div>
              <div id="event-sub-date">
                <v-menu
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="customEventItem.submittedAt"
                      label="Submitted Date"
                      prepend-icon="event"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="customEventItem.submittedAt"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div id="event-resub-date">
                <v-menu
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="customEventItem.resubmittedAt"
                      label="Resubmitted Date"
                      prepend-icon="event"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="customEventItem.resubmittedAt"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div id="event-rejected-date">
                <v-menu
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="customEventItem.rejectedAt"
                      label="Rejected Date"
                      prepend-icon="event"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="customEventItem.rejectedAt"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div id="event-completed-date">
                <v-menu
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="customEventItem.completedAt"
                      label="Completed Date"
                      prepend-icon="event"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="customEventItem.completedAt"
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-2" justify="center">
            <v-btn
              @click="deleteCustomEvent()"
              :loading="deletingCustomEvent"
              v-if="customEventItem.id"
              color="red"
              style="text-transform: none"
              ><v-icon>delete</v-icon> Delete</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="statusConfirmationDialog"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="statusLoading">
        <v-toolbar flat>
          <v-card-title>Change File Status</v-card-title>
          <v-spacer></v-spacer>
          <v-btn text @click="statusConfirmationDialog = false">X</v-btn>
        </v-toolbar>
        <v-card-text class="text-center">
          <v-row justify="center" class="mt-3 mb-3">
            <v-btn
              color="info"
              class="mx-2"
              outlined
              small
              @click="processStatusChange('Open')"
              >Open</v-btn
            >
            <v-btn
              color="warning"
              class="mx-2"
              outlined
              small
              @click="processStatusChange('Cancelled')"
              >Cancelled</v-btn
            >
            <v-btn
              color="red"
              class="mx-2"
              outlined
              small
              @click="processStatusChange('Closed')"
              >Closed</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="packingListMailingDialog"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Send Packing List</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="openPackingListMail()"
            :disabled="packingListItems.length <= 0"
            ><v-icon small>arrow_forward</v-icon></v-btn
          >
          <v-btn text @click="packingListMailingDialog = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list dense>
            <v-list-item
              v-for="item in packingListItems"
              :key="item.bookingContainer.ctoNo"
            >
              <v-list-item-action>
                <v-checkbox v-model="item.selected" color="blue"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-if="item.bookingContainer.containerNo">{{
                  item.bookingContainer.containerNo
                }}</v-list-item-title>
                <v-list-item-title v-else>{{
                  item.bookingContainer.ctoNo
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="packingListMailBodyModal"
      width="600px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="creatingEmails">
        <v-card-text>
          <v-toolbar flat>
            <v-toolbar-title v-if="shipment && shipment.shipmentFile"
              >Packing List 
              <!-- : {{ shipment.shipmentFile.fileNumber }} -
              {{ shipment.vesselName }} {{ shipment.voyage }} -
              {{ shipment.consignee.name }} -->
              </v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn
              text
              :loading="creatingEmails"
              @click="sendPackingListMail"
              color="blue"
              ><v-icon small>mail</v-icon>
            </v-btn>
            <v-btn text @click="packingListMailBodyModal = false">X</v-btn>
          </v-toolbar>
          <v-row>
            <v-col>
              <v-textarea
                label="Email Adresses"
                class="ml-3"
                outlined
                rows="3"
                hint="Please seperate email addresses with a comma (,)"
                v-model="packingList.emailAddresses"
              ></v-textarea>
              <v-textarea
                label="CC Email Adresses"
                class="ml-3"
                outlined
                rows="3"
                hint="Please seperate email addresses with a comma (,)"
                v-model="packingList.ccEmailAddresses"
              ></v-textarea>
              <v-textarea
                label="Reply Email Adresses"
                class="ml-3"
                outlined
                rows="3"
                hint="Please seperate email addresses with a comma (,)"
                v-model="packingList.replyAddresses"
              ></v-textarea>
              <!-- <v-text class="ml-3 hint-text" style="color: red">
                {{ replayAddr() }}
              </v-text> -->
              <!-- <v-text 
                v-if="packingReplyAddresses && packingListCCEmailAddresses"
                class="ml-3 hint-text">
                ** Reply adresses will be used.
                </v-text> 
                <v-text 
                v-if="packingReplyAddresses && !packingListCCEmailAddresses"
                class="ml-3 hint-text">
                ** Reply adresses will be used.
                </v-text> 
                <v-text 
                v-if="!packingReplyAddresses && packingListCCEmailAddresses"
                class="ml-3 hint-text">
                ** CC adresses will be used for reply adresses.
                </v-text> 
                <v-text 
              v-else-if="!packingReplyAddresses && !packingListCCEmailAddresses"
              class="ml-3 hint-text">
              ** No reply addresses will be added if CC and Reply fields are empty.
            </v-text> -->
            </v-col>
          </v-row>
          <p class="ml-2"><strong>Dispatch ID's</strong></p>
          <v-divider class="mb-3"></v-divider>
          <v-row>
            <v-col cols="12">
              <div
                v-for="item in packingListItems"
                :key="item.bookingContainerId"
              >
                <p class="ml-2">
                  {{ item.bookingContainer.ctoNo }} -
                  {{ item.bookingContainer.containerNo }} -

                  {{
                    containers
                      .find(
                        (container) =>
                        container.id == item.bookingContainerId
                      )
                      .containerPallets.map((pallet) => pallet.sscc)
                      .filter(
                        (value, index, self) => self.indexOf(value) === index
                      ).length
                  }}
                  plts -
                  {{
                    containers
                      .find(
                        (container) =>
                        container.id == item.bookingContainerId
                      )
                      .containerPallets.map((pallet) => parseInt(pallet.noCartons))
                      .reduce((a, b) => a + b, 0)
                  }}
                  ctns
                </p>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
   
  </div>
</template>
<script>
import DocumentModal from "@/components/SystemComponents/DocumentModal.vue";
import EventModal from "@/components/SystemComponents/EventModal.vue";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  name: "Documentation",
  components: {
    DocumentModal,
    EventModal,
    VuePdfEmbed,
  },
  data: () => ({
    actions: [],
    activeUsers: [],
    availableDocuments: [],
    addDocumentModal: false,
    additionalDocumentHeaders: [
      {
        text: "Country",
        value: "iso2",
        align: "center",
      },
      {
        text: "Type",
        value: "type",
        align: "left",
      },
      {
        text: "Name",
        value: "name",
        align: "left",
      },
      {
        text: "Integration",
        value: "integrationCode",
        align: "left",
      },
      {
        text: "Regulatory Body",
        value: "regulatoryBody.name",
        align: "left",
      },
      {
        text: "Description",
        value: "description",
        align: "left",
      },
    ],
    addresses: [],
    attachmentKey: 400,
    availableMassActions: [],
    containers: [],
    countryDocuments: [],
    coverKey: 300,
    creatingEmails: false,
    creatingManualDocuments: false,
    documentProcessingOfficeModal: false,
    documents: [],
    documentItem: {},
    documentModal: false,
    documentUpdateKey: 600,
    documentUserAssign: false,
    documentUserKey: 200,
    documentResponsibleParties: [
      { id: 1, name: "Buyer", value: "buyer" },
      { id: 2, name: "Forwarder", value: "forwarder" },
      { id: 3, name: "Shipper", value: "shipper" },
      { id: 4, name: "Consignee", value: "consignee" },
    ],
    customEvents: [],
    customEventItem: {},
    customEventModal: false,
    customEventTypes: [],
    customEventStatuses: [
      "Not started",
      "Pending",
      "Submitted",
      "Resubmitted",
      "Rejected",
      "Completed",
    ],
    deletingCustomEvent: false,
    event: null,
    eventModal: false,
    events: [],
    eventsLoading: false,
    fullscreen: false,
    generatingFileCover: false,
    headers: [
      {
        text: "Actions",
        value: "actions",
        align: "center",
        width: "100px",
      },
      {
        text: "Status",
        value: "status",
        align: "center",
        width: "80px",
      },
      {
        text: "Task",
        value: "integrationStatus",
        align: "center",
        width: "80px",
        groupable: false,
      },
      {
        text: "Reference",
        value: "reference",
        align: "center",
        groupable: false,
        width: "200px",
      },
      {
        text: "Responsible Party",
        value: "responsiblePartyType",
        align: "center",
        groupable: false,
      },
      {
        text: "KPI",
        value: "kpiCategory",
        align: "center",
        groupable: false,
        width: "75px",
      },

      {
        text: "Submitted Date",
        value: "submittedDate",
        align: "center",
        sortable: false,
      },
      {
        text: "Completed Date",
        value: "completedDate",
        align: "center",
        sortable: false,
      },

      {
        text: "Group",
        value: "group",
        align: "center",
        sortable: false,
      },
      {
        text: "Courier",
        value: "courier",
        align: "center",
        width: "100px",
      },
    ],
    imageLoading: false,
    loading: false,
    loadingAdditionalDocuments: false,
    loadingProducts: false,
    loadingUsers: false,
    manualDocument: {},
    manualDocumentContainerKey: 1000,
    massActions: [],
    metrics: [],
    onHoldWarningModal: false,
    openKey: 500,
    orgAdresses: [],
    orgUsers: [],
    packingList: {},
    packingListMailBodyModal: false,
    packingListMailingDialog: false,
    packingListItems: [],
    previewFileCoverModal: false,
    progress: 0,
    refreshKey: 700,
    relationships: [],
    savingCustomEvent: false,
    savingDocumentationUser: false,
    scope: null,
    search: null,
    searchAdditionalDocuments: null,
    searchUser: null,
    selectContainersModal: false,
    selectVariationModal: false,
    setQuantityModal: false,
    shipment: {},
    shipmentChangeLogs: [],
    shipmentProducts: [],
    startingAction: null,
    statusLoading: false,
    statusConfirmationDialog: false,
    tableBillableStates: {},
    tableDeleteStates: {},
    tasks: [],
    taskKey: 800,
    typeKey: 900,
    uploads: [],
    userUpdateKey: 100,
    userScope: null,
  }),
  watch: {
    "$route.params.id": {
      immediate: true,
      handler(value) {
        if (value) {
          this.load(value);
        }
      },
    },
    "customEventItem.courier": {
      handler: function (newCourier, oldCourier) {
        if (newCourier !== oldCourier) {
          this.fetchOrgAddresses(newCourier);
        }
      },
      immediate: true,
    },
    "customEventItem.shipmentCustomEventTypeId": {
      immediate: true,
      handler(val) {
        if (val) {
          let find = this.customEventTypes.find((x) => x.id == val);
          if (find) {
            this.customEventItem.shipmentCustomEventType = find;
          }
        } else {
          this.customEventItem.shipmentCustomEventType = {};
        }
      },
    },
  },
  computed: {
    availableParties() {
      let result = [];
      let parties = [
        "shipper",
        "onBehalfShipper",
        "forwarder",
        "onBehalfForwarder",
        "consignee",
        "onBehalfConsignee",
        "buyer",
        "onBehalfBuyer",
        "firstNotify",
        "secondNotify",
        "courierParty",
      ];
      for (let i = 0; i < parties.length; i++) {
        if (this.shipment[parties[i]]) {
          let findExisting = result.find(
            (x) => x.value == this.shipment[parties[i] + "Id"]
          );

          if (!findExisting) {
            result.push({
              name: this.shipment[parties[i]].name,
              value: this.shipment[parties[i] + "Id"],
            });
          }
        }
      }
      if (this.relationships && this.relationships.length > 0) {
        for (let i = 0; i < this.relationships.length; i++) {
          let findExisting = result.find(
            (x) => x.value == this.relationships[i].customerId
          );
          if (!findExisting) {
            result.push({
              name: this.relationships[i].customer.name,
              value: this.relationships[i].customerId,
            });
          }
        }
      }
      result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      return result;
    },
    documentSpecialComments() {
      let filter = this.documents.filter(
        (x) => x.comment || x.specialInstruction
      );
      let uniqueDocuments = [
        ...new Set(filter.map((x) => x.locationDocumentId)),
      ];
      let result = [];
      for (let i = 0; i < uniqueDocuments.length; i++) {
        let document = filter.find(
          (x) => x.locationDocumentId == uniqueDocuments[i]
        );
        let obj = {
          id: document.id,
          name: document.locationDocument.name,
          comment: document.comment,
          specialInstruction: document.specialInstruction,
        };
        result.push(obj);
      }
      return result;
    },
    filteredDocuments() {
      let result = this.availableDocuments;
      if (this.search) {
        result = result.filter((x) =>
          x.value
            ? x.value.name.toLowerCase().includes(this.search.toLowerCase())
            : x.locationDocument.name
                .toLowerCase()
                .includes(this.search.toLowerCase())
        );
      }

      return result;
    },
    filterUsers() {
      let result = this.orgUsers;
      if (this.searchUser) {
        result = result.filter(
          (x) =>
            x.user.firstname
              .toLowerCase()
              .includes(this.searchUser.toLowerCase()) ||
            x.user.surname
              .toLowerCase()
              .includes(this.searchUser.toLowerCase()) ||
            x.user.emailAddress
              .toLowerCase()
              .includes(this.searchUser.toLowerCase()) ||
            x.user.authProvider
              .toLowerCase()
              .includes(this.searchUser.toLowerCase())
        );
      }
      return result;
    },

    tableWidth() {
      let result = 0;
      this.headers.forEach((x) => {
        result += parseInt(x.width ? x.width.replace("px", "") : 150);
      });
      console.log("Width", result);
      return result + "px";
    },
    validateCustomEvent() {
      let res = true;
      if (this.customEventItem.shipmentCustomEventTypeId) {
        if (this.customEventItem.shipmentCustomEventTypeId === 1) {
          if (
            this.customEventItem.pickupAddressId &&
            this.customEventItem.deliveryAddressId
          ) {
            res = false;
          } else {
            res = true;
          }
        } else {
          res = false;
        }
      }
      return res;
    },
  },
  created() {
    this.getOrganisationAddresses();
    this.getOrganisationUsers();
  },
  unmounted() {
    this.$socket.send(
      JSON.stringify({
        action: "leave",
        room: "shipment_" + this.shipment.id,
        user: {
          id: this.$store.state.user.id,
          name:
            this.$store.state.user.firstname +
            " " +
            this.$store.state.user.surname,
          avatar: this.$store.state.user.avatar,
          company: this.$store.state.currentOrg.name,
        },
      })
    );
    delete this.$socket.onmessage;
  },
  beforeDestroy() {
    this.$socket.send(
      JSON.stringify({
        action: "leave",
        room: "shipment_" + this.shipment.id,
        user: {
          id: this.$store.state.user.id,
          name:
            this.$store.state.user.firstname +
            " " +
            this.$store.state.user.surname,
          avatar: this.$store.state.user.avatar,
          company: this.$store.state.currentOrg.name,
        },
      })
    );
  },
  methods: {
    addContainer(id) {
      let index = this.manualDocument.containers.indexOf(id);
      if (index == -1) {
        this.manualDocument.containers.push(id);
      } else {
        this.manualDocument.containers.splice(index, 1);
      }
      this.manualDocumentContainerKey++;
    },
    addCustomEvent() {
      this.customEventItem = {};
      this.customEventModal = true;
    },
    addManualDocument() {
      this.manualDocument = {
        shipmentId: this.shipment.id,
        count: 1,
        quantityType: "Shipment",
        containers: [],
        individualDocuments: false,
      };
      this.addDocumentModal = true;
    },
    async assignDocumentUser(user) {
      this.savingDocumentationUser = true;
      this.shipment.assignedDocumentUserId = user.user.id;
      this.shipment.assignedDocumentUser = user.user;
      this.documentUserKey++;
      await this.$API.updateShipment({
        id: this.shipment.id,
        assignedDocumentUserId: this.shipment.assignedDocumentUserId,
      });
      this.documentUserAssign = false;
      this.$message.success(
        "Successfully updated assigned documentation user!"
      );
      this.savingDocumentationUser = false;
    },
    calculateMetrics() {
      let result = [];
      let exportDocumentation = this.documents.filter(
        (x) => x.locationDocument.type == "Export"
      );
      if (exportDocumentation.length > 0) {
        let obj = {
          name: "Export Documentation",
          value: exportDocumentation.filter(
            (x) => x.status.toLowerCase() == "complete"
          ).length,
          total: exportDocumentation.filter(x=>x.status.toLowerCase() !='not required').length,
        };
        if (obj.total > 0) {
          obj.percentage = Math.round((obj.value / obj.total) * 100);
        }
        result.push(obj);
      }
      let importDocumentation = this.documents.filter(
        (x) => x.locationDocument.type == "Import"
      );
      if (importDocumentation.length > 0) {
        let obj = {
          name: "Import Documentation",
          value: importDocumentation.filter(
            (x) => x.status.toLowerCase() == "complete"
          ).length,
          total: importDocumentation.filter(x=>x.status.toLowerCase() !='not required').length,
        };
        if (obj.total > 0) {
          obj.percentage = Math.round((obj.value / obj.total) * 100);
        }
        result.push(obj);
      }
      if (this.events.length > 0) {
        let obj = {
          name: "Events",
          value: this.events.filter(
            (x) =>
              x.status.toLowerCase() == "complete" ||
              x.status.toLowerCase() == "completed" ||
              x.status.toLowerCase() == "delivered"
          ).length,
          total: this.events.length,
        };
        if (obj.total > 0) {
          obj.percentage = Math.round((obj.value / obj.total) * 100);
        }
        result.push(obj);
      }
      if (this.customEvents.length > 0) {
        let obj = {
          name: "Custom Events",
          value: this.customEvents.filter(
            (x) =>
              x.status.toLowerCase() == "complete" ||
              x.status.toLowerCase() == "completed"
          ).length,
          total: this.customEvents.length,
        };
        if (obj.total > 0) {
          obj.percentage = Math.round((obj.value / obj.total) * 100);
        }
        result.push(obj);
      }
      this.calculateProgress();
      this.metrics = result;
    },
    calculateProgress() {
      let usableDocs = this.documents.filter(
        (x) => x.status.toLowerCase() != "not required"
      );
      let totalDocs = usableDocs.filter(
        (x) => x.status.toLowerCase() != "not required"
      ).length;
      let completeDocs = usableDocs.filter(
        (x) => x.status.toLowerCase() == "complete"
      ).length;

      let total = totalDocs + this.events.length + this.customEvents.length;
      let completeEvents = this.events.filter(
        (x) =>
          x.status.toLowerCase() == "complete" ||
          x.status.toLowerCase() == "completed" ||
          x.status.toLowerCase() == "delivered"
      ).length;
      let completeCustomEvents = this.customEvents.filter(
        (x) =>
          x.status.toLowerCase() == "complete" ||
          x.status.toLowerCase() == "completed"
      ).length;
      let complete = completeDocs + completeEvents + completeCustomEvents;
      this.progress = total == 0 ? 0 : Math.round((complete / total) * 100);
    },
    async createManualDocuments() {
      try {
        this.creatingManualDocuments = true;
        let result = await this.$API.createManualShipmentDocument(
          this.manualDocument
        );
        this.$emit("refreshDocuments");
        this.$emit("refreshTasks");
        this.creatingManualDocuments = false;
        this.setQuantityModal = false;
        this.addDocumentModal = false;
        this.selectContainersModal = false;
        this.loadDocuments();
        // this.$emit("refreshActions");
        // this.getMassActions();
        this.manualDocument = {};
        this.$message.success("Successfully added document!");
      } catch (e) {
        this.$message.error("An error ocurred");
        this.creatingManualDocuments = false;
      }
    },
    async deleteCustomEvent() {
      this.deletingCustomEvent = true;
      this.$confirm("Are you sure you want to delete this event?", "Confirm", {
        center: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "plain",
      })
        .then(async () => {
          this.customEventItem.isDeleted = true;
          this.customEventItem.isActive = false;
          await this.$API.updateShipmentCustomEvent(
            this.customEventItem,
            this.customEventItem.id
          );
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
          let index = this.customEvents.findIndex(
            (x) => x.id == this.customEventItem.id
          );
          this.customEvents.splice(index, 1);
          this.customEventModal = false;
          this.customEventItem = {};
          this.deletingCustomEvent = false;
        })
        .catch(() => {
          this.deletingCustomEvent = false;
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    async deleteShipmentDocumentType(item) {
      this.$confirm(
        "Are you sure you want to delete this document? All work on it will be lost.",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          this.$set(this.tableDeleteStates, item.id, true);
          item.isDeleted = true;
          item.isActive = false;
          await this.$API.updateShipmentDocumentType(item);
          let index = this.documents.findIndex((x) => x.id == item.id);
          this.documents.splice(index, 1);
          let findAvailableIndex = this.availableDocuments.findIndex(
            (x) => x.id == item.id
          );
          if (findAvailableIndex > -1) {
            this.availableDocuments.splice(findAvailableIndex, 1);
          }
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
          this.$set(this.tableDeleteStates, item.id, false);
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "Delete canceled",
          });
        });
    },
    documentTasks(document) {
      let variations = document.variations
        ? document.variations.map((x) => x.id)
        : [];
      let ids = [document.id, ...variations];
      let tasks = this.tasks.filter(
        (x) =>
          ids.includes(x.shipmentDocumentTypeId) ||
          ids.includes(x.parentShipmentDocumentId)
      );
      return tasks;
    },
    async downloadUrlDocument(doc) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.url;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
    },
    editCustomEvent(event) {
      this.customEventItem = event;
      this.customEventModal = true;
    },
    async fetchOrgAddresses() {
      let res = await this.$API.getAllOrgAdresses(this.customEventItem.courier);
      this.orgAdresses = res;
    },
    async getActions() {
      this.actions = await this.$API.getShipmentActions(this.shipment.id);
    },
    async getContainers() {
      if(this.shipment.id){
        this.containers = await this.$API.getBasicShipmentContainers(
        this.shipment.id
      );
      }
     
    },
    async generateCover() {
      try {
        this.generatingFileCover = true;
        let result = await this.$API.generateShipmentFileCover(
          this.shipment.id
        );
        if (result && result.key) {
          this.shipment.shipmentFile.fileCover = result;
          this.shipment.shipmentFile.fileCoverId = result.id;
        }
        this.generatingFileCover = false;
        this.coverKey++;
        this.$message.success("File cover generated successfully!");
        this.previewFileCoverModal = true;
      } catch (e) {
        console.log(e);
        this.generatingFileCover = false;
        this.$message.error(
          "Oops! an error occurred while genrating the cover"
        );
      }
    },
    getAddressAlias(id) {
      if (id) {
        let find = this.addresses.find((x) => x.id == id);
        if (find) {
          return find.alias;
        } else return "Select Document Processing Office";
      } else {
        return "Select Document Processing Office";
      }
    },
    getDocumentUserDescription(user) {
      if (user) {
        return user.firstname + " " + user.surname;
      } else {
        return "Assign Document User";
      }
    },
    async getEvents() {
      this.eventsLoading = true;
      this.events = await this.$API.getShipmentEvents(this.shipment.id);
      this.customEvents = await this.$API.getShipmentCustomEvents(
        this.shipment.id
      );
      this.calculateMetrics();
      this.customEventTypes = await this.$API.getCustomEventTypes();
      this.eventsLoading = false;
    },
    getIcon(type) {
      switch (type.toLowerCase()) {
        case "buyer":
          return "shopping_cart";
        case "consignee":
          return "call_received";
        case "shipper":
          return "directions_boat";
        case "forwarder":
          return "fast_forward";
        case "notify party":
          return "notifications";
      }
    },
    async getOrganisationAddresses() {
      this.addresses = await this.$API.getOrganisationAddress();
    },
    async getOrganisationUsers() {
      this.loadingUsers = true;
      this.orgUsers = await this.$API.getOrganisationUsers();
      this.loadingUsers = false;
    },
    async getShipmentChangeLog(id) {
      this.shipmentChangeLogs = await this.$API.getShipmentChanges(id);
    },
    async getShipmentProducts() {
      this.loadingProducts = true;
      this.shipmentProducts = await this.$API.getShipmentProducts(
        this.shipment.id
      );
      this.loadingProducts = false;
    },
    async getShipmentRelationships(id) {
      this.relationships = await this.$API.getShipmentRelationships(id);
      this.onHoldWarningModal = this.relationships.some(
        (x) => x.organisationRelationship.clientStatus == "ON HOLD"
      );
    },
    getMainStatusColor(status) {
      switch (status) {
        case "OPEN":
          return "info";
        case "CANCELLED":
          return "warning";
        case "CLOSED":
          return "red";
      }
    },
    getStatusColor(status) {
      switch (status.toLowerCase()) {
        case "action required":
          return "orange";
        case "open":
          return "grey";
        case "not required":
          return "grey";
        case "not started":
          return "grey";
        case "cancelled":
          return "grey";
        case "approved":
          return "green";
        case "complete":
          return "green";
        case "completed":
          return "green";
        case "delivered":
          return "green";
        case "in progress":
          return "indigo";
        case "created":
          return "blue";
        case "submitted":
          return "blue";
        case "resubmitted":
          return "cyan";
        case "draft":
          return "orange";
        case "file generated":
          return "orange";
        case "pending":
          return "orange";
        case status.includes("pending"):
          return "orange";
        case "draft approved":
          return "purple";
        case "revision required":
          return "red";
        case "rejected":
          return "red";
        case "query":
          return "purple";
        case "in transnit":
          return "orange";
        case "failed":
          return "red";
        case "new":
          return "amber";
        case "error":
          return "red";
        case "not created":
          return "red";
        case "in transit":
          return "blue";
        case "validation failed":
          return "red";
        case "inspection failed":
          return "red";
        case "inspection approved":
          return "success";
        case status.toLowerCase().includes("pending"):
          return "orange";
        case status.toLowerCase().includes("approved"):
          return "green";
        case status.toLowerCase().includes("failed"):
          return "red";
        case status.toLowerCase().includes("revoked"):
          return "red";
        default:
          return "grey";
      }
    },
    getStatusIcon(status) {
      switch (status.toLowerCase()) {
        case "action required":
          return "warning";
        case "not required":
          return "close";
        case "cancelled":
          return "cancel";
        case "not started":
          return "schedule";
        case "pending":
          return "pending";
        case "in progress":
          return "sync";
        case "draft":
          return "edit_note";
        case "created":
          return "mark_email_read";
        case "submitted":
          return "mark_email_read";
        case "resubmitted":
          return "mark_email_read";
        case "complete":
          return "task_alt";
        case "completed":
          return "task_alt";
        case "delivered":
          return "task_alt";
        case "file generated":
          return "task";
        case "new":
          return "star";
        case "error":
          return "cancel";
        case "failed":
          return "cancel";
        case "rejected":
          return "cancel";
        case "not created":
          return "error";
        case "approved":
          return "fact_check";
        case "validation failed":
          return "error";
        case "inspection failed":
          return "error";
        case "inspection approved":
          return "fact_check";
        case "in transit":
          return "local_shipping";
        case status.toLowerCase().includes("pending"):
          return "pending";
        case status.toLowerCase().includes("approved"):
          return "fact_check";
        case status.toLowerCase().includes("failed"):
          return "error";
        case status.toLowerCase().includes("revoked"):
          return "error";
        default:
          return "info";
      }
    },
    async getTaskAttachments(task) {
      task.taskAttachments = await this.$API.getTaskAttachments(task.id);
      this.attachmentKey++;
    },
    async getTaskActions(task) {
      if (task.id && !task.taskActions) {
        // this.loadingTaskActions.push(task.id);
        task.taskActions = [];
        task.taskActions = await this.$API.getTaskActions(task.id);
        this.actionKey++;
        //   this.loadingTaskActions.splice(
        //     this.loadingTaskActions.indexOf(task.id),
        //     1
        //   );
      }
    },
    async getUploads() {
      this.uploads = await this.$API.getShipmentDocuments(this.shipment.id);
      this.documentUpdateKey++;
    },
    async load(id) {
      this.loading = true;
      await this.setAccessState();
      this.scope = await this.$API.authoriseScope({
        type: "shipment",
      });
      if (typeof this.scope.tabs == "string") {
        this.scope.tabs = JSON.parse(this.scope.tabs);
      }
      this.shipment = await this.$API.getSingleShipment(id);
      this.tasks = await this.$API.getShipmentTasks(this.shipment.id);
      await this.loadDocuments();
      this.getShipmentRelationships(this.shipment.id);
      this.getShipmentChangeLog(this.shipment.id);
      this.loadOtherDocuments();
      this.getUploads();
      this.getActions();
      this.getEvents();
      this.getContainers()
      this.customEvents = await this.$API.getShipmentCustomEvents(
        this.shipment.id
      );
      setTimeout(() => {
        this.$socket.send(
          JSON.stringify({
            action: "join",
            room: "shipment_" + this.shipment.id,
            user: {
              id: this.$store.state.user.id,
              name:
                this.$store.state.user.firstname +
                " " +
                this.$store.state.user.surname,
              avatar: this.$store.state.user.avatar,
              company: this.$store.state.currentOrg.name,
            },
          })
        );
      }, 1000);
      this.$socket.onopen = () => {
        this.$socket.send(
          JSON.stringify({
            action: "join",
            room: "shipment_" + this.shipment.id,
            user: {
              id: this.$store.state.user.id,
              name:
                this.$store.state.user.firstname +
                " " +
                this.$store.state.user.surname,
              avatar: this.$store.state.user.avatar,
              company: this.$store.state.currentOrg.name,
            },
          })
        );
      };
      this.$socket.onmessage = (data) => {
        let message = JSON.parse(data.data);
        if (this.shipment && this.shipment.id) {
          console.log("Received message", message);
          this.handleMessage(message);
          if (
            message.type == "users" &&
            message.room == "shipment_" + this.shipment.id
          ) {
            this.activeUsers = message.data;
            this.userUpdateKey++;
          }
        }
      };
      await this.getShipmentProducts();
      this.loading = false;
    },
    async loadDocuments(){
      this.massActions = await this.$API.getShipmentMassActions(
        this.shipment.id
      );
      let result = await this.$API.getShipmentDocumentTypes(this.shipment.id);
      this.documents = result;
      this.calculateMetrics();
      let finalResult = [];
      result.sort((a, b) => a.locationDocument.rank - b.locationDocument.rank);
      let uniqueDocuments = [
        ...new Set(result.map((x) => x.locationDocumentId)),
      ];
      this.availableMassActions = [];
      for (let i = 0; i < uniqueDocuments.length; i++) {
        let document = result.find(
          (x) => x.locationDocumentId == uniqueDocuments[i]
        );
        let obj = {
          type: "subheader",
          value: document.locationDocument,
        };
        let filteredObj = result.filter(
          (x) => x.locationDocumentId == uniqueDocuments[i]
        );
        let massActions = this.tasks.filter(
          (x) =>
            filteredObj.map((y) => y.id).includes(x.shipmentDocumentTypeId) ||
            filteredObj.map((y) => y.id).includes(x.parentShipmentDocumentId)
        );
        massActions = massActions.map((x) => x.integrationCode).filter(Boolean);
        let massActionResult = this.massActions.filter((x) =>
          massActions.includes(x.code)
        );
        obj.massActions = massActionResult;
        this.availableMassActions = [
          ...this.availableMassActions,
          ...massActionResult,
        ];
        finalResult.push(obj);
        finalResult = [...finalResult, ...filteredObj];
      }
      this.availableDocuments = finalResult;
    },
    async processStatusChange(status) {
      this.statusLoading = true;

      this.$confirm(
        `Are you sure you want to change the shipment status to ${status}?`,
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          if (status === "Closed") {
            let eventDocuments = this.events.filter(
              (x) => x.shipmentEventLinks && x.shipmentEventLinks.length > 0
            );
            eventDocuments = [].concat.apply(
              [],
              eventDocuments.map((x) => x.shipmentEventLinks)
            );
            eventDocuments = eventDocuments.map(
              (x) => x.shipmentDocumentTypeId
            );
            let filterDocuments = this.documents.filter(
              (x) =>
                x.status != "Complete" &&
                x.status != "Not Required" &&
                !eventDocuments.includes(x.id)
            );
            if (filterDocuments.length > 0) {
              this.$message.error("ERROR: Not All Documents Are Completed");
              this.statusConfirmationDialog = false;
              this.statusLoading = false;
              return;
            }
            let filterEvents = filterDocuments.filter((x) =>
              eventDocuments.includes(x.id)
            );
            let acceptableStatus = ["Submitted", "Complete", "Not required"];
            for (let i = 0; i < filterEvents.length; i++) {
              const eventStatus = acceptableStatus.includes(
                filterEvents[i].status
              );
              if (!eventStatus) {
                this.$message.error("ERROR: Not All Events Have Been Started");
                this.statusConfirmationDialog = false;
                this.statusLoading = false;
                return;
              }
            }
            for (let i = 0; i < this.customEvents.length; i++) {
              const eventStatus = this.customEvents[i].status;
              if (eventStatus != "Complete" && eventStatus != "Completed") {
                this.$message.error(
                  `ERROR: Not All Custom Events Are Completed`
                );
                this.statusConfirmationDialog = false;
                this.statusLoading = false;
                return;
              }
            }
          }

          this.shipment.documentationStatus = status.toUpperCase();
          this.shipment.status = status.toUpperCase();
          await this.$API.updateShipmentFileStatus(this.shipment.id, {
            documentationStatus: this.shipment.documentationStatus,
          });
          this.$message.success("Successfully updated documentation status!");
          this.statusConfirmationDialog = false;
          this.statusLoading = false;
        })
        .catch(() => {
          this.statusConfirmationDialog = false;
          this.statusLoading = false;
        });
    },
    async handleMessage(message) {
      if (typeof message == "string") {
        message = JSON.parse(message);
      }
      if (message.data && typeof message.data == "string") {
        message.data = JSON.parse(message.data);
      }
      if (message.room == "shipment_" + this.shipment.id) {
        if (message.type == "update_document") {
          let documentIndex = this.documents.findIndex(
            (x) => x.id == message.id
          );
          if (documentIndex > -1) {
            this.documents[documentIndex] = {
              ...this.documents[documentIndex],
              ...message.data,
            };
          }
          let findAvailableIndex = this.availableDocuments.findIndex(
            (x) => x.id == message.id
          );
          if (findAvailableIndex > -1) {
            this.availableDocuments[findAvailableIndex] = {
              ...this.availableDocuments[findAvailableIndex],
              ...message.data,
            };
          }
          this.refreshKey++;
          this.calculateMetrics();
        } else if (message.type == "task_update") {
          let findTask = this.tasks.findIndex((x) => x.id == message.id);
          if (findTask > -1) {
            console.log("Updating task", message);
            this.tasks[findTask] = { ...this.tasks[findTask], ...message.data };
            console.log("Updated task", this.tasks[findTask]);
            this.taskKey++;
          }
        }
      }
    },
    async loadOtherDocuments() {
      if (this.shipment.originCountryId) {
        this.loadingAdditionalDocuments = true;
        let exportDocuments = await this.$API.getCountryDocuments(
          this.shipment.originCountryId
        );
        exportDocuments.forEach((x) => {
          x.iso2 = this.shipment.originCountry.iso2;
        });
        let importDocuments = await this.$API.getCountryDocuments(
          this.shipment.destinationCountryId
        );
        importDocuments.forEach((x) => {
          x.iso2 = this.shipment.destinationCountry.iso2;
        });
        this.countryDocuments = [...exportDocuments, ...importDocuments];
        this.loadingAdditionalDocuments = false;
      }
    },
    async refreshDocument(doc) {
      let result = await this.$API.getShipmentDocumentTypeDetail(
        this.documentItem.id
      );
      let findIndex = this.documents.findIndex((x) => x.id == doc.id);
      this.documentItem = { ...this.documentItem, ...result };
      this.documents[findIndex] = { ...this.documents[findIndex], ...result };
    },
    async saveCustomEvent() {
      this.savingCustomEvent = true;

      if (this.customEventItem && this.customEventItem.courier) {
        if (!this.customEventItem.destinationAddressId) {
          let destinationId = await this.$API.getSingleOrganisionAdress(
            this.customEventItem.courier
          );
          this.customEventItem.destinationAddressId = destinationId.id;
        }
        let originId = await this.$API.getSingleUserOrgAdress();
        this.customEventItem.originAddressId = originId.organisationAddressId;
      }
      if (
        this.customEventItem.shipmentCustomEventType &&
        this.customEventItem.shipmentCustomEventType.eventCode
      ) {
        this.customEventItem.eventCode =
          this.customEventItem.shipmentCustomEventType.eventCode;
        this.customEventItem.shipmentId = this.shipment.id;
        if (!this.customEventItem.status || this.customEventItem.status == "") {
          this.customEventItem.status = "Not Started";
        }

        if (
          this.customEventItem.shipmentCustomEventType.eventCode ==
          "ZA_DHL_Waybill"
        ) {
          let res = await this.$API.createManualShipmentDocument({
            shipmentId: this.shipment.id,
            eventCode: this.customEventItem.shipmentCustomEventType.eventCode,
            quantityType: "Shipment",
            submitted: this.customEventItem.submitted,
            resubmitted: this.customEventItem.resubmitted,
            completed: this.customEventItem.completed,
            eventStatus: this.customEventItem.status,
          });
          this.customEventItem.shipmentDocumentTypeId = res.data[0].id;
          this.customEventItem.manuallyCreated = true;
          this.custom;
        }
        await this.$API.createShipmentEvent(this.customEventItem);
        this.getEvents();
      } else {
        this.customEventItem.shipmentId = this.shipment.id;
        this.customEventItem.createdById = this.$store.state.user.id;
        this.customEventItem.status = this.setCustomEventStatus();
        if (this.customEventItem && this.customEventItem.id) {
          this.customEventItem = await this.$API.updateShipmentCustomEvent(
            this.customEventItem,
            this.customEventItem.id
          );
        } else {
          this.customEventItem = await this.$API.createShipmentCustomEvent(
            this.customEventItem
          );
        }
        this.getEvents();
      }

      this.savingCustomEvent = false;
      this.customEventItem = {};
      this.customEventModal = false;
    },
    async sendPackingListMail() {
      this.creatingEmails = true;
      let obj = {
        containerData: this.packingListItems.map((x) => {
          return {
            ctoNo: x.bookingContainer.ctoNo,
            containerNo: x.bookingContainer.containerNo,
            plts: this.containers
              .find((container) => container.id == x.bookingContainerId)
              .containerPallets.map((pallet) => pallet.sscc)
              .filter((value, index, self) => self.indexOf(value) === index)
              .length,
            ctns: this.containers
             .find((container) => container.id == x.bookingContainerId).containerPallets
              .map((pallet) => parseInt(pallet.noCartons))
              .reduce((a, b) => a + b, 0),
          };
        }),
        ctoNos: this.packingListItems.map((x) => x.bookingContainer.ctoNo),
        containerNos: this.packingListItems.map(
          (x) => x.bookingContainer.containerNo
        ),
        shipmentDocumentTypes: this.packingListItems.map((x) => x.id),
        emailAddresses: this.packingList.emailAddresses,
        ccAddresses: this.packingList.ccEmailAddresses,
        replyAddresses: this.packingList.replyAddresses,
        title: `Packing List : ${this.shipment.shipmentFile.fileNumber} - ${this.shipment.vesselName} ${this.shipment.voyage} - ${this.shipment.consignee.name}`,
      };

      let response = await this.$API.sendPackingListMail(obj);
      if (response.status === 200) {
        this.$message.success(response.message);
        this.packingListMailBodyModal = false;
      } else {
        this.$message.error(response.message);
        this.packingListMailBodyModal = false;
      }
      this.creatingEmails = false;
    },
    openPackingListMail() {
      this.packingListItems = this.packingListItems.filter((x) => x.selected);
      this.packingListMailingDialog = false;
      this.packingListMailBodyModal = true;
    },
    openPackingListDialog(items) {
      this.packingListItems = items.filter(
        (x) =>
          x.locationDocument &&
          x.locationDocument.id === 217 &&
          x.status === "Complete"
      );
      this.packingListItems.forEach((x) => (x.selected = true));
      this.packingListMailingDialog = true;
    },
    async setAccessState() {
      let access = await this.$API.getUserScope({
        path: "/documentation",
        module: "Shipments",
      });
      this.userScope = access;
      await this.$store.dispatch("setAccessState", access);
    },
    async setDocumentResponsibleParty(party, document) {
      document.responsiblePartyType = party;
      await this.$API.updateShipmentDocumentType(document);
      this.$message({
        type: "success",
        message: "Successfully updated!",
      });
    },
    selectManualDocument(item) {
      this.manualDocument = {
        ...this.manualDocument,
        parentShipmentDocumentId: item.id,
        parentShipmentDocument: item,
        subtypes: item.locationDocumentSubtypes,
      };
      if (this.manualDocument.subtypes.length == 1) {
        this.manualDocument.locationDocument = this.manualDocument.subtypes[0];
        this.manualDocument.locationDocumentId =
          this.manualDocument.subtypes[0].id;
        // console.log('1: Setting quantity', this.manualDocument)

        this.setQuantityModal = true;
      } else if (this.manualDocument.subtypes.length > 1) {
        this.selectVariationModal = true;
        // console.log('2: Setting quantity', this.manualDocument)
      } else if (this.manualDocument.subtypes.length == 0) {
        delete this.manualDocument.parentShipmentDocumentId;
        delete this.manualDocument.parentShipmentDocument;
        this.manualDocument.locationDocument = item;
        this.manualDocument.locationDocumentId = item.id;
        this.setQuantityModal = true;
        // console.log('3: Setting quantity', this.manualDocument)
      }
    },
    setCustomEventStatus() {
      let status = this.customEventItem.status;
      if (this.customEventItem.submittedAt) {
        status = "Submitted";
        if (this.customEventItem.shipmentCustomEventTypeId == 3) {
          this.customEventItem.completedAt = this.customEventItem.submittedAt;
          status = "Completed";
        }
      }
      if (this.customEventItem.resubmittedAt) {
        status = "Resubmitted";
      }
      if (this.customEventItem.completedAt) {
        status = "Completed";
      }
      return status;
    },
    setDocumentAddess(address) {
      this.shipment.documentProcessingAddressId = address.id;
      this.shipment.documentProcessingAddress = address;
      this.documentProcessingOfficeModal = false;
      this.updateDocumentationAddress();
    },
    setSubtype(item) {
      this.manualDocument.locationDocumentId = item.id;
      this.manualDocument.locationDocument = item;
      this.selectVariationModal = false;
      this.setQuantityModal = true;
    },
    async startMassAction(action) {
      if(action.name == 'Email Packing List') {
       this.openPackingListDialog(this.documents.filter(x => x.locationDocument.id == 217));
      } else {
      this.startingAction = action.code;
      await this.$API.startMassAction(this.shipment.id, action.code);
      let index = this.massActions.indexOf(action);
      let findIndex = this.availableMassActions.findIndex(
        (x) => x.code == action.code
      );
      this.availableMassActions.splice(findIndex, 1);
      this.massActions.splice(index, 1);
      this.$message.success("Successfully started tasks");
      this.startingAction = null;
      }
    },
    async updateDocumentationAddress() {
      await this.$API.updateShipment({
        id: this.shipment.id,
        documentProcessingAddressId: this.shipment.documentProcessingAddressId,
      });
      this.$message.success(
        "Successfully updated document processing address!"
      );
    },

    async updateDocumentBillable(document) {
      try {
        let billable = !document.isBillable;
        this.$set(this.tableBillableStates, document.id, true);
        await this.$API.updateShipmentDocumentType({
          id: document.id,
          isBillable: billable,
        });
        document.isBillable = billable;
        this.$message.success("Document billable status updated!");
        this.$set(this.tableBillableStates, document.id, false);
      } catch (e) {
        this.$set(this.tableBillableStates, document.id, false);
        this.$message.error("Unable to update document billbable status");
      }
    },
    updateDocument(doc) {
      this.documentModal = false;
      let findDoc = this.documents.findIndex((x) => x.id == doc.id);
      this.documents[findDoc] = doc;
    },
    updateEvent(event) {
      this.event = event;
      let index = this.events.findIndex((x) => x.id == event.id);
      this.events[index] = event;
      this.eventModal = false;
    },
    viewDocument(document) {
      this.documentLog = [];
      this.documentItem = this.documents.find((x) => x.id == document.id);
      this.documentTab = "info";
      this.expandedProfile = false;
      this.expandedTask = null;
      this.getUploads();
      for (let i = 0; i < this.documentTasks(document).length; i++) {
        this.getTaskAttachments(this.documentTasks(document)[i]);
        this.getTaskActions(this.documentTasks(document)[i]);
      }
      this.documentModal = true;
      setTimeout(() => {
        this.openKey++;
      }, 250);
    },
    viewEvent(event) {
      this.event = event;
      this.eventModal = true;
    },
  },
};
</script>
<style scoped>
.innerContent {
    max-height: calc(100vh - 36px - 30px) !important; 
    height: 100%;
    width: 100%;
    contain: content;
    overflow-y: scroll;
}</style>